const removeDiacritics = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
// filter should work both ways, either user typed Belém or Belem
export const filterOption = (option, filter) => {
    const normalizedFilter = removeDiacritics(filter).toLowerCase();
    const { label, short_title } = option;
    const normalizedLabel = label ? removeDiacritics(label).toLowerCase() : '';
    const normalizedShortTitle = short_title ? removeDiacritics(short_title).toLowerCase() : '';

    const labelHasString = normalizedLabel.includes(normalizedFilter);
    const shortTitleHasString = normalizedShortTitle.includes(normalizedFilter);

    return normalizedFilter.length > 0
        ? Boolean(
              !option.disabled &&
                  !option.excludeFromSearch &&
                  (labelHasString || shortTitleHasString),
          )
        : true;
};
